<template>
  <div>
    <v-container class="mb-8 pb-8 gradient">
      <v-row justify="center">
        <v-col sm="8">
          <h1 class="mt-8 text-h4">Une question ? Un problème ?</h1>
          <h2 class="text-h4 mt-2 font-weight-medium">
            <span class="primary--text">Contactez-nous !</span>
          </h2>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="8">
          <div class="mt-2 text-body-2">
            Nous savons comme il peut être frustrant de rester bloqué(e) sur un
            problème qui nous empêche de travailler correctement. C'est pourquoi
            notre équipe se tient à votre disposition pour y répondre le plus
            rapidement possible !
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row justify="center">
        <v-col sm="6" class="text-h5">
          <v-icon color="primary" size="38" class="mr-2">mdi-email</v-icon>
          Envoyez-nous un message
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col sm="6">
          <v-form ref="form">
            <v-text-field
              outlined
              label="Nom"
              v-model="form.name"
              required
              :rules="nameRules"
            /><v-text-field
              outlined
              label="Adresse email"
              v-model="form.email"
              :rules="emailRules"
              required
            />
            <v-text-field
              outlined
              label="Sujet"
              v-model="form.subject"
              required
              :rules="[(v) => !!v || 'Subject is required']"
            />
            <v-textarea
              outlined
              label="Message"
              v-model="form.message"
              required
              :rules="[(v) => !!v || 'Message is required']"
            />
            <v-row>
              <v-col class="grow d-flex justify-end">
                <v-btn color="primary" @click="send()">envoyer</v-btn></v-col
              >
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Contact",
  components: {},
  data() {
    return {
      form: {},
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    send() {
      try {
        if (!this.$refs.form.validate()) return;

        const url = `${process.env.VUE_APP_BASE_API}public/contact`;

        axios
          .post(url, this.form)
          .then(() => {
            this.form = {};
            alert(
              "Votre message a bien été envoyé. Notre service vous recontactera dans les meilleurs délais."
            );
          })
          .catch((error) => console.log(error));

        console.dir(this.form);
      } catch (error) {
        console.dir(error);
      }
    },
  },
};
</script>